import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import arrow from "../../assets/images/arrow-down.png";
import icon from "../../assets/images/favicon.png";
import logo from "../../assets/images/logo.png";
import useScheduler from "../../hooks/useScheduler";
import DepartmentFilter from "../compound/DepartmentFilter";
import Loading from "../compound/Loader";
import NavigationControls from "../compound/NavigationBar";
import Sidebar from "../compound/Sidebar";
import SchedulerModal from "./SchedulerModal";

const SchedulerView = () => {
  const {
    isLoading,
    collapseSideBar,
    handleSideBarViewChange,
    filters,
    setSelectedFilters,
    selectedFilters,
    currentViewDate,
    handleViewChange,
    goPrev,
    goNext,
    isOpen,
    toggleDropdown,
    user,
    Logout,
    schedulerLoading,
    weekNumber,
    isModalOpen,
    closeModal,
    selectedEvent,
    imageUrl,
    container,
    defaultView,
    isInitialLoadForFilter,
  } = useScheduler();

  if (isLoading) return <Loading />;

  return (
    <>
      <div
        className={
          "custom-scheduler-header " + (collapseSideBar ? "full-width" : "")
        }
      >
        <div className="logo">
          <img src={logo} alt="Web-Concept Logo" className="logo" />
          <img src={icon} alt="Web-Concept Logo" className="icon" />
          <span className="arrow-icon">
            {collapseSideBar ? (
              <IoIosArrowForward onClick={handleSideBarViewChange} />
            ) : (
              <IoIosArrowBack onClick={handleSideBarViewChange} />
            )}
          </span>
        </div>
        <div className="head-wrap">
          <div className="filter-content">
            <DepartmentFilter
              departments={filters?.departments}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
              isInitialLoadForFilter={isInitialLoadForFilter}
            />
          </div>
          <NavigationControls
            currentViewDate={currentViewDate}
            handleViewChange={handleViewChange}
            goPrev={goPrev}
            goNext={goNext}
            defaultView={defaultView}
          />
          <div
            className={"user-profile " + (isOpen ? "active" : "")}
            onClick={toggleDropdown}
          >
            <img src={imageUrl} alt="Podio File" className="profile" />
            <p>{user.name}</p>
            <button>
              <img src={arrow} alt="arrow" className="arrow" />
            </button>
            <div className="drop-down">
              <p onClick={Logout}>Log ud</p>
            </div>
          </div>
        </div>
      </div>
      <div className="scheduler-container">
        {!collapseSideBar && (
          <Sidebar
            filters={filters}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            isInitialLoadForFilter={isInitialLoadForFilter}
          />
        )}
        <div
          className={
            "scheduler-content " + (schedulerLoading ? "blur-content" : "")
          }
        >
          {weekNumber && (
            <div className="custom-scheduler-text">
              <div className="week-number">{weekNumber}</div>
            </div>
          )}
          <div
            ref={container}
            className="scheduler-grid"
            style={{ width: "100%", height: "87%" }}
          />
          <SchedulerModal
            isOpen={isModalOpen}
            onClose={closeModal}
            eventData={selectedEvent}
          />
        </div>
      </div>
    </>
  );
};

export default SchedulerView;
