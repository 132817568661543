const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (date) => {
  let day = date.getDate();
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const dkFormatDate = (date) => {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Input must be a valid Date object");
  }
  let formattedDate = date.toLocaleDateString("da-DK", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  // let parts = formattedDate.split(" ");
  // if (parts.length === 3) {
  //   parts[1] = parts[1].replace(".", "");
  //   parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
  //   parts[0] = parts[0];
  //   formattedDate = `${parts[0]} ${parts[1]} ${parts[2]}`;
  // }

  return formattedDate;
};

export const formatMonth = (date) => {
  let month = months[date.getMonth()];
  let year = date.getFullYear();
  return `${month} ${year}`;
};
export const getCurrentWeek = () => {
  const today = new Date();

  const dayOfWeek = today.getDay();
  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  const diffToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

  const monday = new Date(today);
  monday.setDate(today.getDate() - diffToMonday);

  const sunday = new Date(today);
  sunday.setDate(today.getDate() + diffToSunday);

  return {
    weekStart: monday,
    weekEnd: sunday,
  };
};

export const formatDateTime = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Format the date using the Danish locale
  const formattedDate = startDate.toLocaleDateString("da-DK", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  // Format the start time in 24-hour format
  const startTime = startDate.toLocaleTimeString("da-DK", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // Format the end time in 24-hour format
  const endTime = endDate.toLocaleTimeString("da-DK", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // Combine date and time into the desired format
  return `${formattedDate}, ${startTime} - ${endTime}`;
};

export const getDuration = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const durationInMilliseconds = endDate - startDate;
  const totalMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours === 0) {
    return `${minutes} Minutter`;
  } else if (minutes === 0) {
    return `${hours} Timer`;
  } else {
    return `${hours} Timer and ${minutes} Minutter`;
  }
};
