// DepartmentFilter.js
import React, { useState, useEffect } from "react";
import Select from "react-select";

const DepartmentFilter = ({
  departments,
  setSelectedFilters,
  selectedFilters,
  isInitialLoadForFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    if (selectedFilters.departments.length > 0) {
      const presetOptions = departments.filter((department) =>
        selectedFilters.departments.includes(department.label)
      );
      setSelectedOption(presetOptions);
    }
  }, [departments, selectedFilters]);

  const handleChange = (selectedOptions) => {
    isInitialLoadForFilter.current = false;
    setSelectedOption(selectedOptions);
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    setSelectedFilters((prevState) => ({
      ...prevState,
      departments: selectedValues,
    }));
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={departments}
      isMulti
      placeholder="vælg afdelinger"
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          cursor: "pointer",
        }),
      }}
    />
  );
};

export default DepartmentFilter;
